import React, { useState } from 'react'
import styles from '@styles/components/LandingPageActions.module.scss'
import { ActionButton, Button, Icon } from 'ui'
import CloseIcon from '/public/s2_icon_close.svg'
import { useRouter } from 'next/router'
import { useTranslations } from 'use-intl'
import { useMonitoringActions } from '@hooks/useMonitoring'

const LandingPageActions = () => {
  const t = useTranslations()
  const [isOpen, setIsOpen] = useState(false)
  const router = useRouter()
  const openDialog = () => setIsOpen(true)
  const closeDialog = () => setIsOpen(false)
  const { sendEvent } = useMonitoringActions()

  function handleGetStartedClick() {
    router.push('/discover')
    sendEvent('landing:hero:getStarted')
  }

  function handleVideoButtonClick() {
    sendEvent('landing:hero:seeHowItWorks')
    openDialog()
  }

  return (
    <div className={styles['container']}>
      <div className={styles['actions']}>
        <Button variant="primary" onClick={handleVideoButtonClick} size="l">
          {t('page:landing:hero:videoButton')}
        </Button>
        <Button
          treatment="outline"
          variant="primary"
          onClick={handleGetStartedClick}
          size="l">
          {t('page:landing:hero:getStarted')}
        </Button>
        {isOpen && (
          <div className={styles['overlay']} onClick={closeDialog}>
            <ActionButton
              className={styles['close-button']}
              onClick={closeDialog}>
              <Icon slot="icon" className="icon-m">
                <CloseIcon />
              </Icon>
            </ActionButton>
            <div
              className={styles['dialog']}
              onClick={e => e.stopPropagation()}>
              <video
                className={styles['video']}
                autoPlay
                playsInline
                controls
                onEnded={closeDialog}>
                <source
                  src="landing_page/howard_vid_compressed.mp4"
                  type="video/mp4"
                />
              </video>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default LandingPageActions
