import React from 'react'
import styles from '@styles/components/LandingPageFeatureCards.module.scss'
import { useTranslations } from 'use-intl'
import { UseTranslationsHookResult } from '@concerns/i18n/types'

const getFeatureCards = (
  t: UseTranslationsHookResult
): {
  title: string
  desc: string
}[] => {
  return [
    {
      title: t('page:landing:feature:volumetric'),
      desc: t('page:landing:feature:volumetric:desc')
    },
    {
      title: t('page:landing:feature:visualize'),
      desc: t('page:landing:feature:visualize:desc')
    },
    {
      title: t('page:landing:feature:export'),
      desc: t('page:landing:feature:export:desc')
    },
    {
      title: t('page:landing:feature:style'),
      desc: t('page:landing:feature:style:desc')
    },
    {
      title: t('page:landing:feature:ideate'),
      desc: t('page:landing:feature:ideate:desc')
    },
    {
      title: t('page:landing:feature:team'),
      desc: t('page:landing:feature:team:desc')
    }
  ]
}

const LandingPageFeatureCards = () => {
  const t = useTranslations()
  const cards = getFeatureCards(t)

  return (
    <>
      {cards.map(({ title, desc }, i) => (
        <div className={styles['card']} key={title + i}>
          <video
            className={styles['card-img']}
            autoPlay
            muted
            loop
            playsInline
            preload="none">
            <source
              src={`landing_page/feature_cards/${i + 1}.mp4`}
              type="video/mp4"
            />
            <meta itemProp="name" content="Onboarding features video" />
            <meta itemProp="description" content="Project Neo features" />
          </video>
          <div className={styles['card-content']}>
            <span className={styles['card-title']}>{title}</span>
            <span className={styles['card-description']}>{desc}</span>
          </div>
        </div>
      ))}
    </>
  )
}

export default LandingPageFeatureCards
