import React from 'react'
import BarebonesProjectCard from './BarebonesProjectCard'
import styles from '@styles/components/LandingPageCategoryCards.module.scss'
import { useTranslations } from 'use-intl'
import { UseTranslationsHookResult } from '@concerns/i18n/types'

const normalizeCardDimensions = (
  actualImageWidth: number,
  actualImageHeight: number
): { width: number; height: number } => {
  let width = actualImageWidth
  let height = actualImageHeight

  const aspectRatio = width / height
  const minWidth = 240
  const minHeight = 220
  const maxHeight = 377
  let maxWidth = 240

  if (aspectRatio > 1.3) {
    maxWidth = 340
  }

  if (width > maxWidth) {
    width = maxWidth
    height = width / aspectRatio
  } else if (width < minWidth) {
    width = minWidth
    height = width / aspectRatio
  }

  if (height > maxHeight) {
    height = maxHeight
    width = height * aspectRatio
  } else if (height < minHeight) {
    height = minHeight
    width = height * aspectRatio
  }

  width = Math.max(minWidth, Math.min(maxWidth, width))
  height = Math.max(minHeight, Math.min(maxHeight, height))

  return {
    width: Math.ceil(width),
    height: Math.ceil(height)
  }
}

const getCategoryCards = (
  t: UseTranslationsHookResult
): Array<{
  tag: string
  img: string
  href: string
  width: number
  height: number
}> => {
  return [
    {
      tag: t('page:landing:carousel:logos'),
      img: 'carousel_logos',
      href: '',
      ...normalizeCardDimensions(212, 300)
    },
    {
      tag: t('page:landing:carousel:illustrations'),
      img: 'carousel_illustrations',
      href: '',
      ...normalizeCardDimensions(212, 380)
    },
    {
      tag: t('page:landing:carousel:icons'),
      img: 'carousel_icons',
      href: '',
      ...normalizeCardDimensions(240, 240)
    },
    {
      tag: t('page:landing:carousel:typography'),
      img: 'carousel_custom_typography',
      href: '',
      ...normalizeCardDimensions(240, 240)
    },
    {
      tag: t('page:landing:carousel:flyers'),
      img: 'carousel_flyers',
      href: '',
      ...normalizeCardDimensions(212, 380)
    },
    {
      tag: t('page:landing:carousel:posters'),
      img: 'carousel_posters',
      href: '',
      ...normalizeCardDimensions(212, 300)
    },
    {
      tag: t('page:landing:carousel:infographics'),
      img: 'carousel_infographics',
      href: '',
      ...normalizeCardDimensions(240, 240)
    },
    {
      tag: t('page:landing:carousel:ads'),
      img: 'carousel_print_ads',
      href: '',
      ...normalizeCardDimensions(300, 212)
    },
    {
      tag: t('page:landing:carousel:sketches'),
      img: 'carousel_sketches',
      href: '',
      ...normalizeCardDimensions(212, 300)
    },
    {
      tag: t('page:landing:carousel:mockups'),
      img: 'carousel_mockups',
      href: '',
      ...normalizeCardDimensions(212, 300)
    },
    {
      tag: t('page:landing:carousel:comps'),
      img: 'carousel_comps',
      href: '',
      ...normalizeCardDimensions(240, 240)
    },
    {
      tag: t('page:landing:carousel:packaging'),
      img: 'carousel_packaging_design',
      href: '',
      ...normalizeCardDimensions(212, 300)
    },
    {
      tag: t('page:landing:carousel:isometric'),
      img: 'carousel_isometric_designs',
      href: '',
      ...normalizeCardDimensions(300, 212)
    }
  ]
}

const LandingPageCategoryCards = ({
  isMobileLayout
}: {
  isMobileLayout: boolean
}) => {
  const t = useTranslations()
  const cards = getCategoryCards(t)

  return (
    <>
      {cards.map(({ tag, width, height, href, img }, i) => (
        <div
          key={i + '-barebones-card-wrapper-' + tag}
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center'
          }}>
          <BarebonesProjectCard
            key={i + '-barebones-card-' + tag}
            isMobileLayout={isMobileLayout}
            imgSrc={`landing_page/category_cards/${img}.png`}
            tag={tag}
            cardWidth={width}
            cardHeight={height}
            onClick={undefined}
            href={href}
          />
          <div className={styles['badge-container']}>
            <div className={styles['badge']}>
              <span>{tag}</span>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}

export default LandingPageCategoryCards
